<template>
  <div class="container">

    <div class="main">
      <top-timer></top-timer>
      <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
        <!---->
      </div>
      <div class="player listmain type-ssg lgklsf game-lgklsf game-ssg-rx">
        <div class="data ctop">
          <ul class="datatop">
            <li isclick="t" class="selected" style="width:16.6%;" itemid="24628" tmax="10" tmin="2" balltype="*|2" balls="*|2-10">
              <ol class="td_name">任选二</ol>
              <ol id="rate_24628" class="td_rate">4.2</ol>
            </li>
            <li style="width:16.6%;" itemid="24629" tmax="10" tmin="3" balltype="*|3" balls="*|3-10">
              <ol class="td_name">任选三</ol>
              <ol id="rate_24629" class="td_rate">14.5</ol>
            </li>
            <li style="width:16.6%;" itemid="24630" tmax="10" tmin="4" balltype="*|4" balls="*|4-10">
              <ol class="td_name">任选四</ol>
              <ol id="rate_24630" class="td_rate">48</ol>
            </li>
            <li style="width:16.6%;" itemid="24631" tmax="10" tmin="5" balltype="*|5" balls="*|5-10">
              <ol class="td_name">任选五</ol>
              <ol id="rate_24631" class="td_rate">140</ol>
            </li>
            <li style="width:16.6%;" itemid="24633" tmax="10" tmin="2" balltype="lz|*" balls="*|2-10">
              <ol class="td_name">选二连组</ol>
              <ol id="rate_24633" class="td_rate">23.88</ol>
            </li>
            <li style="width:17%;" itemid="24635" tmax="10" tmin="3" balltype="rx|Z1-Z2-Z3" balls="*|3-10">
              <ol class="td_name">选三前组</ol>
              <ol id="rate_24635" class="td_rate">623</ol>
            </li>
          </ul>
        </div>
        <!-- 水果 -->
        <div class="data">
          <ul>
            <li class="selected" balls="01" itid="01" code="" id="_01">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b01">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_01" name="" title="01" balls="01"></ol>
            </li>
            <li balls="02" itid="02" code="" id="_02">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b02">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_02" name="" title="02" balls="02"></ol>
            </li>
            <li balls="03" itid="03" code="" id="_03">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b03">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_03" name="" title="03" balls="03"></ol>
            </li>
            <li balls="04" itid="04" code="" id="_04">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b04">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_04" name="" title="04" balls="04"></ol>
            </li>
            <li balls="05" itid="05" code="" id="_05" class="">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b05">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_05" name="" title="05" balls="05"></ol>
            </li>
            <li balls="06" itid="06" code="" id="_06">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b06">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_06" name="" title="06" balls="06"></ol>
            </li>
            <li balls="07" itid="07" code="" id="_07">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b07">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_07" name="" title="07" balls="07"></ol>
            </li>
            <li balls="08" itid="08" code="" id="_08">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b08">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_08" name="" title="08" balls="08"></ol>
            </li>
            <li balls="09" itid="09" code="" id="_09" class="">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b09">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_09" name="" title="09" balls="09"></ol>
            </li>
            <li balls="10" itid="10" code="" id="_10">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b10">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_10" name="" title="10" balls="10"></ol>
            </li>
            <li balls="11" itid="11" code="" id="_11">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b11">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_11" name="" title="11" balls="11"></ol>
            </li>
            <li balls="12" itid="12" code="" id="_12">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b12">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_12" name="" title="12" balls="12"></ol>
            </li>
            <li balls="13" itid="13" code="" id="_13">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b13">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_13" name="" title="13" balls="13"></ol>
            </li>
            <li balls="14" itid="14" code="" id="_14">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b14">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_14" name="" title="14" balls="14"></ol>
            </li>
            <li balls="15" itid="15" code="" id="_15" class="">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b15">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_15" name="" title="15" balls="15"></ol>
            </li>
            <li balls="16" itid="16" code="" id="_16">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b16">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_16" name="" title="16" balls="16"></ol>
            </li>
            <li balls="17" itid="17" code="" id="_17">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b17">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_17" name="" title="17" balls="17"></ol>
            </li>
            <li balls="18" itid="18" code="" id="_18">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b18">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_18" name="" title="18" balls="18"></ol>
            </li>
            <li balls="19" itid="19" code="" id="_19">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b19">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_19" name="" title="19" balls="19"></ol>
            </li>
            <li balls="20" itid="20" code="" id="_20">
              <ol class="td_name">
                <li class="balls_ccq">
                  <p class="b20">&nbsp;&nbsp;</p>
                </li>
              </ol>
              <ol style="width:100px" class="td_chkbox"><input type="checkbox" value="item_20" name="" title="20" balls="20"></ol>
            </li>
          </ul>
        </div>
        <!-- --- -->
      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [],
    };
  },
  components: {
    yushe,
    topTimer,
  },
  watch: {
    amount(val) {
      this.rowsData.forEach((item) => {
        if (item.selected) {
          item.cash = val;
        }
      });
      this.$forceUpdate();
    },
  },
  created() {},
  computed: {
    //  预设按钮是否能点击 筛选数据传到下注弹框
    isDisabled() {
      let b = true;
      this.xiazhuList = [];
      this.rowsData.forEach((item) => {
        if (item.cash) {
          this.xiazhuList.push(item);
          b = false;
        }
      });
      if (!b) {
        this.$store.commit("setXZList", this.xiazhuList);
      } else {
        this.$store.commit("setXZList", []);
      }
      return b;
    },
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },

  methods: {
    // 切换 不一个类型得 表格
    setTableType(num) {
      this.tableType = num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style  scoped>
.listmain .data ul li {
  width: 25%;
}
.listmain div.ctop .datatop li ol {
  width: auto !important;
  max-width: none !important;
  display: block;
  border-right: none !important;
}
element.style {
  width: 16.6%;
}
</style>